const config = {
    mui_pro_License_Key : "b5aaed8b75e9139e4d22473261d34883Tz02NDgwNCxFPTE3MTM1MzkwODg2MzAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    baseUrl : "https://apiv2.dev-auth.surepoint.cloud",
    domainUrl : ".surepoint.cloud",
    SSORedirectUri : "http://localhost:3000",
    graphql_URL : "https://apiv2.dev-auth.surepoint.cloud/time-management/graphql",
    graphql_URL_TEMP : "https://apiv2.dev-auth.surepoint.cloud/main/graphql",
    validateToken_URL: "https://apiv2.dev-auth.surepoint.cloud/sso/v1/validate-token",
}

export default config;
export const env_name = "dev" 